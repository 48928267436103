import React from "react";
import styles from "./Prefooter.module.scss";
import pic from "../../assets/images/prefooter_img.png";
import { useTranslation } from "react-i18next";

const Prefooter = ({ onRegisterClick, onLoginClick }) => {
  const { t } = useTranslation("prefooter");

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.group}>
          <h1 className={styles.title}>
            ТВОЯ ПЕРША
            НАГОРОДА
          </h1>
          <div className={styles.desc_group}>
            <p className={styles.desc}>
              Отримай 100 XP за прив’язку акаунта Steam
            </p>
          </div>
          <button className={styles.btn} onClick={onRegisterClick}>Забрати нагороду</button>
        </div>
        <img src={pic} className={styles.group_img} alt="Prefooter" />
      </div>
    </div>
  );
};

export default Prefooter;
