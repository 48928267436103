import { useState } from "react";
import styles from "./OfferRules.module.scss";
import close from "../../../assets/images/icons/close-btn.svg";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";

const OfferRules = ({ closeForm, onRegisterClick }) => {
  const { t } = useTranslation(["offer"]);
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      console.log("hello");
      closeForm();
    }
  };

  return (
    <div className={styles.wrapper} onClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={styles.closeBtn} onClick={onRegisterClick}>
          <img src={close} alt="" />
        </div>
        <div className={styles.title}>{t("offer_header")}</div>
        <div className={styles.offer}>
          <p>{t("p1")}</p>
          <p>{t("p2")}</p>
          <p>
            <strong>{t("p3")}</strong>
          </p>
          <p>
            <strong>{t("Пропозиція")} - </strong>
            {t("акція “X-Pass”, яка проводиться Організатором; ")}
          </p>
          <p>
            <strong>{t("Організатор")} - </strong>
            {t(
              "(далі Сайт) ресурс Johnny24 та відповідальні особи у його складі; "
            )}
          </p>
          <p>
            <strong>{t("Ігровий аккаунт")} – </strong>
            {t(
              "особистий обліковий запис Учасника, зареєстрований в Організатора; "
            )}
          </p>
          <p>
            <strong>{t("Ігровий ідентифікатор")} – </strong>
            {t(
              "номер Ігрового аккаунта Учасника, який зареєстрований в Організатора; ​​Основні правила – правила роботи, прийому ставок та парі Організатора, які розміщуються на Сайті. "
            )}
          </p>
          <p>
            <strong>{t("Термін проведення")} – </strong>
            {t(
              "часовий проміжок встановлений Організатором, який починається 04.09.2024 00:00 і триватиме до офіційного інформування Організатором про завершення Пропозиції; "
            )}
          </p>
          <p>
            <strong>{t("Місце проведення Пропозиції")} – </strong>
            {t(
              "Пропозиція проводиться на Сайті X-Pass. "
            )}
          </p>
          <p>
            <strong>{t("Правила")} – </strong>
            {t("офіційні правила Пропозиції, що розміщуються на Сайті. ")}
          </p>
          <p>
            <strong>{t("Учасник ")} – </strong>
            {t(
              "гравець, який має валідний Ігровий аккаунт на ресурсі Організатора;"
            )}
          </p>
          <p>
            <strong>{t("Приз ")} – </strong>
            {t("виграш отриманий за XP в результаті активності в Пропозиції; ")}
          </p>
          <p>
            <strong>{t("XP")} – </strong>
            {t(
              "одиниця прогресу Учасника на Ігровому акаунті, отриманого в результаті виконання умов Квесту-(ів).  "
            )}
          </p>

          <p>
            <strong>{t("FreeBet")} – </strong>
            {t("ставка без ризику за рахунок Організатора; ")}
          </p>
          <p>
            <strong>{t("FreeSpins")} – </strong>
            {t(
              "безкоштовні прокрути у тій чи іншій грі казино на ресурсі Організатора; "
            )}
          </p>
          <p>
            <strong>{t("SportBonus")} – </strong>
            {t(
              "грошовий єквівалент на аккаунті Учасника, який може бути використаний на Сайті для формування спортивних ставок. Цей бонус потребує відіграш та ці умови будуть зазначені в детальних правилах отриманного бонусу. "
            )}
          </p>
          <p>
            <strong>{t("Фізичний подарунок (та Гаджети)")} – </strong>
            {t(
              "матеріальний предмет, який Учасник може отримати у виді Приза в разі виконання умов Пропозиції. Фізичний подарунок є реальним товаром, який буде доставлено або вручено особисто на підставі попередніх домовленостей з Організатором. "
            )}
          </p>
          <p>
            <strong>{t(" Інвентар та Скіни")} – </strong>
            {t(
              "ігровий продукт платформи “Steam” отриманий Учасником у виді Приза. Даний Приз не може бути використаним в Ігровому акаунті в Організатора, так як є стороннім продуктом. Інформація про вартість та зміст Інвентаря або Скіна зазначена в умовах Призу."
            )}
          </p>
          <p>
            <strong>{t("Квести")} – </strong>
            {t(
              "підбірка завдань, за виконання яких Учасник отримує XP, доступні на Ігровому аккаунті; "
            )}
          </p>
          <p>
            <strong>{t("Обліковий запис Steam")} – </strong>
            {t(
              "особистий обліковий запис Учасника на ресурсі “Steam”, який буде застосований для отримання Інвентаря та Скінів (Призів).  "
            )}
          </p>

          <p>
            <strong>{t("main_terms")}</strong>
          </p>
          <p>
            <strong>1.1. </strong>Пропозиція – акція “X-Pass”, яка проводиться
            Організатором.
          </p>
          <p>
            <strong>1.2. </strong>Організатор – (далі Сайт) ресурс Johnny24 та
            відповідальні особи у його складі.
          </p>
          <p>
            <strong>1.3. </strong>Ігровий аккаунт - особистий обліковий запис
            Учасника, зареєстрований в Організатора.
          </p>

          <p>
            <strong>{t("main_terms_2")}</strong>
          </p>
          <p>
            <strong>2.1. </strong>Ігровий ідентифікатор - номер Ігрового
            аккаунта Учасника, який зареєстрований в Організатора.
          </p>
          <p>
            <strong>2.1.1. </strong>Термін проведення – часовий проміжок
            встановлений Організатором, який починається 04.09.2024 00:00 і
            триватиме до офіційного інформування Організатором про завершення
            Пропозиції.
          </p>
          <p>
            <strong>2.1.2. </strong>Місце проведення Пропозиції – Пропозиція
            проводиться на Сайті X-Pass
          </p>
          <p>
            <strong>2.1.3. </strong>Правила – офіційні правила Пропозиції, що
            розміщуються на Сайті.
          </p>

          <p>
            <strong>{t("main_terms_3")}</strong>
          </p>
          <p>
            <strong>3.1. </strong>XP - одиниця прогресу Учасника на Ігровому
            акаунті, отриманого в результаті виконання умов Квесту-(ів).
          </p>
          <p>
            <strong>3.2. </strong>FreeBet — ставка без ризику за рахунок
            Організатора.
          </p>
          <p>
            <strong>3.3. </strong>Учасником, при накопиченні достатньої
            кількості XP можуть бути отримані FreeBet, FreeSpins, Sportbonus,
            гаджети, інвентар та скіни, фірмовий мерч. Склад Призів, що
            передбачають отримання предметів на акаунті Steam, визначається
            відповідно до правил платформи Steam на момент активації Приза і
            може відрізнятися від зображень на Сайті.
          </p>
          <p>
            <strong>3.4. </strong>У разі вибору в якості Приза будь-якого
            предмета або комплекту предметів, які мають бути зараховані через
            акаунт Steam, Учасник повинен активувати отриманий Приз протягом 30
            (Тридцяти) хвилин з моменту його отримання. При покупці лутбоксу з Інвентарем або Скіном всередині відміна покупки неможлива.
          </p>

          <p>
            <strong>{t("main_terms_4")}</strong>
          </p>
          <p>
            <strong>4.1. </strong>Фізичний подарунок (та Гаджети) – матеріальний
            предмет, який Учасник може отримати у виді Приза в разі виконання
            умов Пропозиції.
          </p>
          <p>
            <strong>4.2. </strong>Якщо Учасник акції не може забрати приз,
            подальша відповідальність за отримання Приза залишається виключно за
            домовленним сторонами отримувачем.
          </p>
          <p>
            <strong>4.3. </strong>Протягом 10 днів з моменту оголошення
            результатів Організатор зв'яжеться з Переможцями, які отримали право
            на отримання призу, та погодить умови доставки виграного призу.
          </p>
          <p>
            <strong>4.4. </strong>Результати визначення Переможців остаточні та
            оскарженню не підлягають.
          </p>

          <p>
            <strong>{t("main_terms_5")}</strong>
          </p>
          <p>
            <strong>5.1. </strong>Своєю участю в Акції, Учасник підтверджує, що
            ознайомлений з Правилами, їх зміст та умови йому зрозумілі, він з
            ними повністю згоден і зобов'язується їх дотримуватися.
          </p>
          <p>
            <strong>5.2. </strong>Організатор має право в будь-який час в
            односторонньому порядку змінювати умови та правила проведення Акції,
            інформація про це розміщується Організатором на Сайті.
          </p>
          <p>
            <strong>5.3. </strong>Акція доступна лише для одного ігрового
            рахунку на людину, сім'ю, квартиру, комп'ютер чи IP-адресу.
          </p>
          <p>
            <strong>5.4. </strong>Організатор залишає за собою право анулювати
            бонус та відмовити Учаснику у його наданні без пояснення причин у
            разі, якщо під час перевірки облікового запису Учасника виникнуть
            підозри у порушенні чесності гри або застосуванні стратегій, які
            компанія на свій розсуд вважає зловмисними.
          </p>
        </div>
        <div className={styles.btn} onClick={onRegisterClick}>
          <Button title="Ок" />
        </div>
      </div>
    </div>
  );
};

export default OfferRules;
