// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./assets/fonts/fonts.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.kz {
  font-family: "Roboto";
}
.kz button,
.kz a {
  font-family: "Roboto";
}

.ru {
  font-family: "HelveticaNeue";
}
.ru button,
.ru a {
  font-family: "HelveticaNeue";
}

ul,
li {
  list-style-type: none;
}

a {
  color: unset;
  text-decoration: none;
}

body {
  background-color: #230432;
}

button {
  background-color: unset;
  border: none;
  outline: none;
}

.container-main {
  margin: 0 auto;
  width: 80%;
}

.loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Toastify .Toastify__toast-container .Toastify__toast {
  background-color: #11362C;
}
.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button {
  align-items: center;
  color: #E0E0E0;
  display: flex;
  justify-content: center;
  opacity: 1;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.yellow {
  color: #EAD40C;
}

@media (max-width: 1200px) {
  .container-main {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;;;EAGE,sBAAA;EACA,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;AAEE;;EAEE,qBAAA;AAAJ;;AAIA;EACE,4BAAA;AADF;AAGE;;EAEE,4BAAA;AADJ;;AAKA;;EAEE,qBAAA;AAFF;;AAKA;EACE,YAAA;EACA,qBAAA;AAFF;;AAKA;EACE,yBAAA;AAFF;;AAKA;EACE,uBAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKA;EACE,cAAA;EACA,UAAA;AAFF;;AAKA;EACE,mBAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,OAAA;EACA,eAAA;EACA,QAAA;EACA,MAAA;AAFF;;AAOI;EACE,yBAAA;AAJN;AAMM;EACE,mBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAJR;;AAUA;EACE,cAAA;AAPF;;AAUA;EACE;IACE,UAAA;EAPF;AACF","sourcesContent":["@import url('./assets/fonts/fonts.scss');\n\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n.kz {\n  font-family: 'Roboto';\n\n  button,\n  a {\n    font-family: 'Roboto';\n  }\n}\n\n.ru {\n  font-family: 'HelveticaNeue';\n\n  button,\n  a {\n    font-family: 'HelveticaNeue';\n  }\n}\n\nul,\nli {\n  list-style-type: none;\n}\n\na {\n  color: unset;\n  text-decoration: none;\n}\n\nbody {\n  background-color: #230432;\n}\n\nbutton {\n  background-color: unset;\n  border: none;\n  outline: none;\n}\n\n.container-main {\n  margin: 0 auto;\n  width: 80%;\n}\n\n.loader {\n  align-items: center;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n}\n\n.Toastify {\n  .Toastify__toast-container {\n    .Toastify__toast {\n      background-color: #11362C;\n\n      .Toastify__close-button {\n        align-items: center;\n        color: #E0E0E0;\n        display: flex;\n        justify-content: center;\n        opacity: 1;\n        position: absolute;\n        right: 16px;\n        top: 50%;\n        transform: translateY(-50%);\n      }\n    }\n  }\n}\n\n.yellow {\n  color: #EAD40C;\n}\n\n@media (max-width: 1200px) {\n  .container-main {\n    width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
