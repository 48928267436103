// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_wrapper__rU9sj {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.Form_wrapper__rU9sj .Form_container__-Vxdf {
  background-color: #230432;
  border-radius: 8px;
  max-width: 700px;
  padding: 20px 40px;
  position: relative;
  width: 100%;
}
.Form_wrapper__rU9sj .Form_container__-Vxdf .Form_closeBtn__Sflxs {
  align-items: center;
  background-color: #2F0444;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 20px;
  transition: all 0.2s linear;
  width: 40px;
}

@media (max-width: 850px) {
  .Form_wrapper__rU9sj .Form_container__-Vxdf {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    padding-left: 14px;
    padding-right: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modals/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,oCAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,OAAA;EACA,eAAA;EACA,QAAA;EACA,MAAA;EACA,UAAA;AACF;AACE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AACI;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;AACN;;AAQA;EAGI;IACE,mBAAA;IACA,aAAA;IACA,uBAAA;IACA,YAAA;IACA,kBAAA;IACA,mBAAA;EAPJ;AACF","sourcesContent":[".wrapper {\n  align-items: center;\n  background-color: rgba(0, 0, 0, .5);\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 3;\n\n  .container {\n    background-color: #230432;\n    border-radius: 8px;\n    max-width: 700px;\n    padding: 20px 40px;\n    position: relative;\n    width: 100%;\n\n    .closeBtn {\n      align-items: center;\n      background-color: #2F0444;\n      border-radius: 8px;\n      cursor: pointer;\n      display: flex;\n      height: 40px;\n      justify-content: center;\n      position: absolute;\n      right: 24px;\n      top: 20px;\n      transition: all .2s linear;\n      width: 40px;\n\n      // &:hover {\n      //   background-color: #494711;\n      // }\n    }\n  }\n}\n\n@media (max-width: 850px) {\n  .wrapper {\n\n    .container {\n      align-items: center;\n      display: flex;\n      justify-content: center;\n      height: 100%;\n      padding-left: 14px;\n      padding-right: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Form_wrapper__rU9sj`,
	"container": `Form_container__-Vxdf`,
	"closeBtn": `Form_closeBtn__Sflxs`
};
export default ___CSS_LOADER_EXPORT___;
