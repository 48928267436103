import { useSelector } from "react-redux";
import styles from "./Product.module.scss";
import defaultProduct from "../../assets/images/defaultProduct.png";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../components/Toast/Toast";
import { useState } from "react";
import loader from "../../assets/images/icons/loader.svg";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../slices/authSlice";
import { updateUserAuth } from "../../utils/authUtils";
import { useTranslation } from "react-i18next";
import {useRef, useEffect} from "react"

const Product = ({ onLoginClick, quest, openModal }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(["main", "notifications"]);
  const buy = async (id, type) => {
    // if (!user.steam_trade_url) {
    //   toast(<Toast message={t('notifications:add_trade_link')}
    //     status='warning' />, {
    //     hideProgressBar: true
    //   })

    //   return
    // }

    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shop_items/${id}/buy_${type}`,
        null,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        if (type === "rea_bonus") {
          toast(<Toast message={t("notifications:present_send_bonus")} />, {
            hideProgressBar: true,
          });
        } else if (type === "merch_prize") {
          toast(<Toast message={t("notifications:present_send_merch")} />, {
            hideProgressBar: true,
          });
        } 
        else {
          toast(<Toast message={t("notifications:present_send")} />, {
            hideProgressBar: true,
          });
        }

        const user = await updateUserAuth();

        if (user) {
          dispatch(
            updateAuth({
              isAuthenticated: true,
              user: user,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);

      if (error.response) {
        if (
          error.response.data.errors.includes("user_has_not_enough_pm_points")
        ) {
          toast(
            <Toast
              message={t("notifications:not_enough_cash")}
              status="warning"
            />,
            {
              hideProgressBar: true,
            }
          );
        } else if (
          error.response.data.errors.includes("rea_bonus_callback_error") ||
          error.response.data.errors.includes("user_reached_limit_24h")
        ) {
          toast(
            <Toast
              message={"На жаль, ви перевищили ліміт покупок на день"}
              status="error"
            />,
            {
              hideProgressBar: true,
            }
          );
        } else {
          toast(
            <Toast
              message={t("notifications:not_enough_cash")}
              status="error"
            />,
            {
              hideProgressBar: true,
            }
          );
        }
      } else {
        toast(
          <Toast
            message={t("notifications:technical_issues")}
            status="error"
          />,
          {
            hideProgressBar: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const onBuyClick = () => {
    buy(quest.id, quest.item_type);
  };
  const [isExpanded, setIsExpanded] = useState (false);
  const [isLongText, setIsLongText] = useState (false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;
  
      const textContent = element.textContent || element.innerText || "";
      const wordCount = textContent.split(/\s+/).filter((word) => word).length;
      if (wordCount > 6) {
        setIsLongText(true);
      }
    }
  }, [quest.description]);
  

  return (
    <div className={styles.container}>
      <div
        className={styles.imgWrapper}
        style={{
          backgroundImage: `url(${quest.image ? quest.image : defaultProduct})`,
        }}
      >
        {/* <img src={quest.image ? quest.image : defaultQuest} alt="" /> */}
      </div>

      <div className={styles.chestTitleWrapper}>
        <div className={styles.chestTitle}>
          <div className={styles.chestName}>{quest.price} XP</div>
        </div>
      </div>

      <div className={styles.productName}>{quest.name}</div>

      <div className={styles.questTaskWrapper}>
        <div
          className={styles.questTask}
          ref={descriptionRef}
          style={{
            maxHeight: isExpanded ? "none" : "45px",
            overflow: isExpanded ? "visible" : "hidden",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: quest.description }} />
        </div>
        {isLongText && (
          <button className={styles.readMoreBtn} onClick={() => openModal({ ...quest, onBuyClick, isAuthenticated, loading, onLoginClick })}>
          {t("Докладніше")}
        </button>
        )}
      </div>
      {isAuthenticated ? (
        <div className={styles.btn} onClick={onBuyClick}>
          <button disabled={loading}>
            {loading ? (
              <img className={styles.loader} src={loader} alt="" />
            ) : (
              t("take")
            )}
          </button>
        </div>
      ) : (
        <div onClick={onLoginClick} className={styles.btn}>
          <button>{t("Придбати")}</button>
        </div>
      )}
    </div>
  );
};

export default Product;
