import { useState } from "react";
import styles from "./OfferRulesAccept.module.scss";
import close from "../../../assets/images/icons/close-btn.svg";
import Button from "../../Button/Button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../../slices/authSlice";

const OfferRulesAccept = ({ closeForm, onRegisterClick }) => {
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      console.log("hello");
      closeForm();
    }
  };

  const dispatch = useDispatch();

  const acceptOffer = async () => {
    console.log("accept");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile`,
        {
          public_offer_accepted: true,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        dispatch(
          updateAuth({
            isAuthenticated: true,
            user: response.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const publicOfferAccepted = useSelector(
    (state) => state?.auth?.user?.public_offer_accepted
  );

  if (publicOfferAccepted === null || publicOfferAccepted === undefined) {
    return null; // Или любой другой контент, который вы хотите показать вместо индикатора загрузки
  }

  return (
    <div className={styles.wrapper} onClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={styles.title}>Правила та умови акції X-Pass:</div>
        <div className={styles.offer}>
          <p>
            X-Pass – твоя унікальна пропозиція від Johnny24 з купівлі призів та
            подарунків за ігровий прогрес.
          </p>
          <p>
            Виконуй квести та отримуй XP, які можна обміняти на FreeSpins,
            FreeBet, SportBonus, лімітований мерч сайту, гаджети та ігрові
            матеріали.
          </p>
          <p>
            <strong>Основні терміни:</strong>
          </p>
          <p>
            <strong>1. Пропозиція - </strong>акція “X-Pass”, яка проводиться
            Організатором;
          </p>
          <p>
            <strong>2. Організатор - </strong>(далі Сайт) ресурс Johnny24 та
            відповідальні особи у його складі;
          </p>
          <p>
            <strong>3. Ігровий аккаунт – </strong>особистий обліковий запис
            Учасника, зареєстрований в Організатора;
          </p>
          <p>
            <strong>4. Ігровий ідентифікатор – </strong>номер Ігрового аккаунта
            Учасника, який зареєстрований в Організатора; ​​Основні правила –
            правила роботи, прийому ставок та парі Організатора, які
            розміщуються на Сайті;
          </p>
          <p>
            <strong>5. Термін проведення – </strong>часовий проміжок
            встановлений Організатором, який починається 04.09.2024 00:00 і
            триватиме до офіційного інформування Організатором про завершення
            Пропозиції;
          </p>
          <p>
            <strong>6. Місце проведення Пропозиції – </strong>Пропозиція
            проводиться на Сайті X-Pass.
          </p>
          <p>
            <strong>7. Правила – </strong>офіційні правила Пропозиції, що
            розміщуються на Сайті.
          </p>
          <p>
            <strong>8. Учасник – </strong>гравець, який має валідний Ігровий
            аккаунт на ресурсі Організатора;
          </p>
          <p>
            <strong>9. Приз – </strong>виграш отриманий за XP в результаті
            активності в Пропозиції;
          </p>
          <p>
            <strong>10. XP – </strong>одиниця прогресу Учасника на Ігровому
            акаунті, отриманого в результаті виконання умов Квесту-(ів).
          </p>
          <p>
            <strong>11. FreeBet – </strong>ставка без ризику за рахунок
            Організатора;
          </p>
          <p>
            <strong>12. FreeSpins – </strong>безкоштовні прокрути у тій чи іншій
            грі казино на ресурсі Організатора;
            <a href="https://xpass24.com/">https://xpass24.com/</a>
          </p>
          <p>
            <strong>13. SportBonus – </strong>процесс проверки и/или
            подтверждения персональных данных Участника.
          </p>
          <p>
            <strong>14. Фізичний подарунок (та Гаджети) – </strong> матеріальний
            предмет, який Учасник може отримати у виді Приза в разі виконання
            умов Пропозиції. Фізичний подарунок є реальним товаром, який буде
            доставлено або вручено особисто на підставі попередніх домовленостей
            з Організатором.
            <a href="https://johnny24.net/">https://johnny24.net/</a>
          </p>
          <p>
            <strong>15. Інвентар та Скіни – </strong>ігровий продукт платформи
            “Steam” отриманий Учасником у виді Приза. Даний Приз не може бути
            використаним в Ігровому акаунті в Організатора, так як є стороннім
            продуктом. Інформація про вартість та зміст Інвентаря або Скіна
            зазначена в умовах Призу.
          </p>
          <p>
            <strong>16. Квести – </strong>підбірка завдань, за виконання яких
            Учасник отримує XP, доступні на Ігровому аккаунті;
          </p>
          <p>
            <strong>17. Обліковий запис Steam – </strong>особистий обліковий
            запис Учасника на ресурсі “Steam”, який буде застосований для
            отримання Інвентаря та Скінів (Призів).
          </p>

          <p>
            <strong>1. Основні положення Пропозиції:</strong>
          </p>
          <p>
            <strong>1.1. </strong>Пропозиція – акція “X-Pass”, яка проводиться
            Організатором.
          </p>
          <p>
            <strong>1.2. </strong>Організатор – (далі Сайт) ресурс Johnny24 та
            відповідальні особи у його складі.
          </p>
          <p>
            <strong>1.3. </strong>Ігровий аккаунт - особистий обліковий запис
            Учасника, зареєстрований в Організатора.
          </p>
          {/* <p><strong>1.4. </strong>В Акции запрещается принимать участие: работникам и представителям Организатора, аффилированным с ними лицам, близким родственникам таких лиц (согласно Кодексу Республики Казахстан «О браке (супружестве) и семье», близкие родственники – родители (родитель), дети, усыновители (удочерители), усыновленные (удочеренные), полнородные и неполнородные братья и сестры, дедушки, бабушки, внуки) и представителям третьих лиц, имеющих установленные гражданско-правовые и иные отношения с Организатором Акции; лицам без гражданства и гражданам других государств, а также лицам, ограниченным в участии в азартных играх и (или) пари.</p>
          <p><strong>1.5. </strong>Акция не является лотереей по смыслу, предусмотренному Законом Республики Казахстан «О лотереях и лотерейной деятельности», является стимулирующим розыгрышем, направленным исключительно на привлечение новых клиентов к Организатору; формирование и поддержание интереса к услугам Организатора.</p>
          <p><strong>1.6. </strong>Организатор оставляет за собой право вносить изменения и обновлять содержание или формат Правил, а также исключать любой из Призов из розыгрыша в Акции в любое время без предварительного уведомления. Информация о внесенных изменениях размещается Организатором на Промо-сайте и/или Сайте. Организатор вправе в любое время завершить Акцию, в этом случае информация о завершении Акции размещается на Промо-сайте и/или на Сайте не менее чем за 7 (Семь) календарных дней до даты завершения Акции.</p>
          <p><strong>1.7. </strong>Участвуя в Акции, Участник подтверждает ознакомление и полное согласие с Правилами, и принятие на себя обязательства соблюдать все их условия и требования.</p> */}

          <p>
            <strong>2. Умови участі в Пропозиції:</strong>
          </p>
          <p>
            <strong>2.1. </strong>Ігровий ідентифікатор - номер Ігрового
            аккаунта Учасника, який зареєстрований в Організатора.
          </p>
          <p>
            <strong>2.1.1. </strong>Термін проведення – часовий проміжок
            встановлений Організатором, який починається 04.09.2024 00:00 і
            триватиме до офіційного інформування Організатором про завершення
            Пропозиції.
          </p>
          <p>
            <strong>2.1.2. </strong>Місце проведення Пропозиції – Пропозиція
            проводиться на Сайті X-Pass
          </p>
          <p>
            <strong>2.1.3. </strong>Правила – офіційні правила Пропозиції, що
            розміщуються на Сайті.
          </p>
          {/* <p><strong>2.2. </strong>За каждый выполненный Квест Участник получает РМ баллы, которые он в последующем может обменять на Призы. Полученные Участником Призы отображаются после авторизации Участника на Промо-сайте и/или Сайте и/или в Мобильном приложении.</p>
          <p><strong>2.3. </strong>FreeBet полученным в качестве Приза можно воспользоваться в течении трех дней после появления его в личном кабинете на Сайте.</p>
          <p><strong>2.4. </strong>Ставки типа «система» и ставки, рассчитанные по услуге CashOut или созданные позже, чем дата и время завершения Квеста текущего дня, не принимают участие в Акции. В «экспрессах» учитывается общий коэффициент.</p>
          <p><strong>2.5. </strong>FreeBet ставки не учитываются при выполнении Квестов.</p> */}

          <p>
            <strong>3. Призи та Бонуси.</strong>
          </p>
          <p>
            <strong>3.1. </strong>XP - одиниця прогресу Учасника на Ігровому
            акаунті, отриманого в результаті виконання умов Квесту-(ів).
          </p>
          <p>
            <strong>3.2. </strong>FreeBet — ставка без ризику за рахунок
            Організатора.
          </p>
          <p>
            <strong>3.3. </strong>Учасником, при накопиченні достатньої
            кількості XP можуть бути отримані FreeBet, FreeSpins, Sportbonus,
            гаджети, інвентар та скіни, фірмовий мерч. Склад Призів, що
            передбачають отримання предметів на акаунті Steam, визначається
            відповідно до правил платформи Steam на момент активації Приза і
            може відрізнятися від зображень на Сайті.
          </p>
          <p>
            <strong>3.4. </strong>У разі вибору в якості Приза будь-якого
            предмета або комплекту предметів, які мають бути зараховані через
            акаунт Steam, Учасник повинен активувати отриманий Приз протягом 30
            (Тридцяти) хвилин з моменту його отримання. При покупці лутбоксу з
            Інвентарем або Скіном всередині відміна покупки неможлива.
          </p>

          <p>
            <strong>4. Умови отримання фізичних призів.</strong>
          </p>
          <p>
            <strong>4.1. </strong>Фізичний подарунок (та Гаджети) – матеріальний
            предмет, який Учасник може отримати у виді Приза в разі виконання
            умов Пропозиції.
          </p>
          <p>
            <strong>4.2. </strong>Якщо Учасник акції не може забрати приз,
            подальша відповідальність за отримання Приза залишається виключно за
            домовленним сторонами отримувачем.
          </p>
          <p>
            <strong>4.3. </strong>Протягом 10 днів з моменту оголошення
            результатів Організатор зв'яжеться з Переможцями, які отримали право
            на отримання призу, та погодить умови доставки виграного призу.
          </p>
          <p>
            <strong>4.4. </strong>Результати визначення Переможців остаточні та
            оскарженню не підлягають.
          </p>
          {/* <p><strong>4.5. </strong>Организатор вправе в любое время в одностороннем порядке изменять условия и правила проведения Акции. Информация об этом размещается Организатором на Промо-сайте и/или на Сайте.</p>
          <p><strong>4.6. </strong>Акция доступна только для одного игрового счета на человека, семью, квартиру, компьютер или IP-адрес.</p>
          <p><strong>4.7. </strong>Организатор оставляет за собой право аннулировать любой Приз и отказать Участнику в его предоставлении без объяснения причин в случае, если во время проверки Логина Участника возникнут подозрения в нарушении честности игры или применении стратегий, которые Организатор по своему усмотрению считает злоумышленными (мультиаккаунтинг, игра по некорректным коэффициентам, арбитражным ситуациям и т.д.).</p>
          <p><strong>4.8. </strong>Организатор оставляет за собой право исключить любого Участника из Акции и розыгрыша без объяснения причины.</p>
          <p><strong>4.9. </strong>Претензии по спорным вопросам принимаются в течение 60 (Шестидесяти) дней с момента возникновения такого вопроса. При этом датой возникновения спорного вопроса считается дата события, результатом которого является появление данного вопроса. По истечении этого срока никакие претензии не принимаются.</p> */}

          <p>
            <strong>5. Заключні положення.</strong>
          </p>
          <p>
            <strong>5.1. </strong>Своєю участю в Акції, Учасник підтверджує, що
            ознайомлений з Правилами, їх зміст та умови йому зрозумілі, він з
            ними повністю згоден і зобов'язується їх дотримуватися.
          </p>
          <p>
            <strong>5.2. </strong>Організатор має право в будь-який час в
            односторонньому порядку змінювати умови та правила проведення Акції,
            інформація про це розміщується Організатором на Сайті.
          </p>
          <p>
            <strong>5.3. </strong>Акція доступна лише для одного ігрового
            рахунку на людину, сім'ю, квартиру, комп'ютер чи IP-адресу.
          </p>
          <p>
            <strong>5.4. </strong>Організатор залишає за собою право анулювати
            бонус та відмовити Учаснику у його наданні без пояснення причин у
            разі, якщо під час перевірки облікового запису Учасника виникнуть
            підозри у порушенні чесності гри або застосуванні стратегій, які
            компанія на свій розсуд вважає зловмисними.
          </p>
        </div>
        <div className={styles.btn} onClick={acceptOffer}>
          <Button title="Ok" />
        </div>
      </div>
    </div>
  );
};

export default OfferRulesAccept;
